<template>
  <div>
    <Form
      ref="registerForm"
      :model="form"
      :rules="rules"
      @keydown.enter.native="handleSubmit"
    >
      <FormItem prop="userName">
        <Input
          v-model="form.userName"
          placeholder="用户名"
          autocomplete="username"
        >
          <Icon type="ios-person" :size="16" slot="prefix" />
        </Input>
      </FormItem>

      <FormItem prop="nickName">
        <Input
          v-model="form.nick_name"
          placeholder="昵称"
          v-bind:style="{ marginTop: 12 + 'px' }"
          autocomplete="nickname"
        >
          <Icon :size="16" type="md-create" slot="prefix"></Icon>
        </Input>
      </FormItem>

      <FormItem prop="email">
        <Input
          v-model="form.email"
          placeholder="邮箱"
          v-bind:style="{ marginTop: 12 + 'px' }"
          autocomplete="email"
        >
          <Icon type="ios-mail" :size="16" slot="prefix" />
        </Input>
      </FormItem>

      <FormItem prop="password">
        <Input
          type="password"
          v-model="form.password"
          password
          placeholder="密码"
          v-bind:style="{ marginTop: 12 + 'px' }"
          autocomplete="new-password"
        >
          <Icon :size="14" type="md-lock" slot="prefix"></Icon>
        </Input>
      </FormItem>

      <FormItem prop="repassword">
        <Input
          type="password"
          v-model="form.repassword"
          password
          placeholder="确认密码"
          v-bind:style="{ marginTop: 12 + 'px' }"
          autocomplete="new-password"
        >
          <Icon :size="14" type="md-lock" slot="prefix"></Icon>
        </Input>
      </FormItem>

      <FormItem prop="campus">
        <!-- 第一个选择器  选择学校  -->
        <Select
          v-model="campus"
          placeholder="请选择学校"
          @on-change="handleCampusSelected"
        >
          <Icon type="ios-school" :size="16" slot="prefix" />
          <Option
            v-for="item in campusList"
            :value="item.value"
            :key="item.value"
          >
            {{ item.value }}
          </Option>
        </Select>
      </FormItem>
      <FormItem>
        <!-- 第二个选择器  选择学院  -->
        <Select v-model="faculty" placeholder="请选择学院">
          <Icon type="logo-buffer" :size="16" slot="prefix" />
          <Option
            v-for="item in facultyList"
            :value="item.value"
            :key="item.value"
            >{{ item.value }}</Option
          >
        </Select>
      </FormItem>

      <FormItem>
        <Checkbox v-model="checkBox">
          <span
            >I agree to
            <a
              @click="
                () => {
                  this.modal = true;
                }
              "
              >"Terms and Conditions"</a
            >
            of Shangxue</span
          >
        </Checkbox>
      </FormItem>

      <FormItem>
        <Button @click="handleSubmit" type="primary" long>确认注册</Button>
      </FormItem>

      <FormItem>
        <Button @click="handleBack" type="primary" long>返回</Button>
      </FormItem>
    </Form>
    <terms-and-conditions-modal
      v-model="modal"
      @close="
        () => {
          modal = false;
        }
      "
    ></terms-and-conditions-modal>
  </div>
</template>
<script>
import TermsAndConditionsModal from "../terms-and-conditions-modal/terms-and-conditions-modal.vue";
export default {
  components: {
    TermsAndConditionsModal
  },
  name: "RegisterForm",
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter your password again"));
      } else if (value !== this.form.password) {
        callback(new Error("The two input passwords do not match!"));
      } else {
        callback();
      }
    };
    return {
      modal: false,
      //  register from的数据绑定
      checkBox: false,
      form: {
        userName: "",
        nick_name: "",
        email: "",
        password: ""
      },
      campus: "",
      campusList: [],
      faculty: "",
      facultyList: [],
      rules: {
        userName: [
          {
            required: true,
            message: "username cannot be empty",
            trigger: "blur"
          },
          {
            type: "string",
            pattern:
              /^([a-zA-Z])[a-zA-Z_-]*[\w_-]*[\S]$|^([a-zA-Z])[0-9_-]*[\S]$|^[a-zA-Z]*[\S]$/,
            min: 3,
            max: 20,
            message:
              "Minimum three characters, maximum 20 characters, only letter and number ",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: "Email cannot be empty",
            trigger: "blur"
          },
          { type: "email", message: "Incorrect email format", trigger: "blur" }
        ],
        nickName: [
          { required: false, trigger: "blur" },
          { type: "string", max: 10, trigger: "blur" }
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            type: "string",
            pattern:
              /^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){0,})(?!.*\s).{8,20}$/,
            message:
              "Min 8 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number",
            trigger: "blur"
          }
        ],
        repassword: [{ validator: validatePassCheck, trigger: "blur" }]
      }
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          if (this.checkBox !== true) {
            this.$Message.error({
              content: "注册前请阅读并同意Terms and Conditions！",
              duration: 2
            });
            return;
          }
          this.$emit("on-success-valid", {
            userName: this.form.userName,
            password: this.form.password,
            email: this.form.email,
            nick_name: this.form.nick_name,
            campus: this.campus,
            faculty: this.faculty
          });
          this.checkBox = false;
        }
      });
    },
    handleBack() {
      this.$router.push("/login");
    },
    handleCampusSelected(selectedCampus) {
      this.axios.get(`api/campus/${selectedCampus}/faculties`).then(resp => {
        // console.log(resp)
        const facaulties = resp.data;
        this.facultyList = facaulties.map(faculty => ({ value: faculty }));
        this.faculty = facaulties[0];
      });
    }
  },
  mounted: function () {
    this.axios.get("api/campuses").then(resp => {
      // console.log(resp)
      const campuses = resp.data;
      this.campusList = campuses.map(campus => ({ value: campus }));
      //   设置默认值
      if (campuses) {
        //  设置默认的campus
        this.campus = campuses[0];
        //   把得到的 string array  转成  object array
        this.axios.get(`api/campus/${campuses[0]}/faculties`).then(resp1 => {
          // console.log(resp)
          const facaulties = resp1.data;
          //   把得到的 string array  转成  object array
          this.facultyList = facaulties.map(faculty => ({ value: faculty }));
          this.faculty = facaulties[0];
        });
      }
    });
    this.$Message.config({
      top: 50,
      duration: 3
    });
  }
};
</script>
