<style lang="less" scoped>
@import "./Registe.less";
</style>

<template>
  <div class="login">
    <div class="login-con">
      <Card icon="log-in" title="注册" :bordered="false">
        <div class="form-con">
          <div style="text-align: center">
            <img
              style="height: 90px; margin-bottom: 8%"
              src="../assets/logo2.jpg"
            />
          </div>
          <register-form @on-success-valid="handleSubmit"></register-form>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import RegisterForm from "../components/register-form";

import { mapActions } from "vuex";

export default {
  components: {
    RegisterForm
  },
  data() {
    return {
      checkBox: false
    };
  },
  methods: {
    ...mapActions(["handleLogin", "getUserInfo"]),

    handleSubmit({ userName, nick_name, email, password, campus, faculty }) {
      //console.log(userName, nick_name, password);
      const userInfo = {
        username: userName,
        email: email,
        display_name: nick_name,
        password: password,
        campus: campus,
        faculty: faculty,
        user_type: "student"
      };

      const userInfoJson = JSON.stringify(userInfo);
      // console.log(userInfoJson);

      this.axios
        .post("api/users", userInfoJson)
        .then(() => {
          // console.log(resp)
          this.axios
            .post("api/sessions", userInfoJson)
            .then(response => {
              // console.log(response)
              this.$Message.success(`Login successful!`);
              const token = response["data"]["token"];
              const user = response["data"]["user"];
              this.$store.commit("login", { user: user, token: token });
              this.$router.push("/dashboard");
            })
            .catch(() => {
              // console.log(error.response)
              // console.log("异常处理")
              this.$Message.error(`用户名或密码错误`);
            });
          // this.$router.push('/login')
        })
        .catch(() => {
          // console.log(error.response)
          // console.log("异常处理")
          this.$Message.error(`This username is exist!`);
        });
      this.$router.push("/login");
    }
  }
};
</script>

<style></style>
